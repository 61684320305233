import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import * as dat from 'lil-gui'
import { clamp } from 'three/src/math/MathUtils'

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Text
        const meshMaterial = new THREE.MeshNormalMaterial()
        const textGeometry = new TextGeometry(
            'Hi,\nThis is\nLimeWolf.top',
            {
                font: font,
                size: 0.5,
                height: 0.3,
                curveSegments: 20,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.03,
                bevelOffset: 0,
                bevelSegments: 20
            }
        )
        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, meshMaterial)
        scene.add(text)
    }
)

/**
 * Objects
 */
// Donuts
const donutGeometry = new THREE.TorusGeometry(0.2, 0.1, 32, 64)
const donutMaterial = new THREE.MeshNormalMaterial()

const donutGroup = new THREE.Group()

for(let i = 0; i < 100; i++)
{
    const donut = new THREE.Mesh(donutGeometry, donutMaterial)
    donut.position.x = (Math.random() - 0.5) * 10
    donut.position.y = (Math.random() - 0.5) * 10
    donut.position.z = (Math.random() - 0.5) * 10
    donut.rotation.x = Math.random() * Math.PI
    donut.rotation.y = Math.random() * Math.PI
    const scale = clamp(Math.random(), 0.3, 1)
    donut.scale.set(scale, scale, scale)

    donutGroup.add(donut)
}
scene.add(donutGroup)

// Cone
const coneGeometry = new THREE.ConeGeometry(0.15, 0.4, 32, 64)
const coneMaterial = new THREE.MeshNormalMaterial()

const coneGroup = new THREE.Group()

for(let i = 0; i < 100; i++)
{
    const cone = new THREE.Mesh(coneGeometry, coneMaterial)
    cone.position.x = (Math.random() - 0.5) * 10
    cone.position.y = (Math.random() - 0.5) * 10
    cone.position.z = (Math.random() - 0.5) * 10
    cone.rotation.x = Math.random() * Math.PI
    cone.rotation.y = Math.random() * Math.PI
    const scale = clamp(Math.random(), 0.3, 1)
    cone.scale.set(scale, scale, scale)

    coneGroup.add(cone)
}
scene.add(coneGroup)

// Sphere
const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#50C7A9' });
const sphereGeometry = new THREE.SphereGeometry(80, 10);
const backgroundSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
backgroundSphere.scale.set(-1, -1, 1);
sphereMaterial.side = THREE.BackSide
scene.add(backgroundSphere);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 3
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enablePan = false
controls.minPolarAngle = Math.PI / 6
controls.maxPolarAngle = Math.PI - Math.PI / 6
controls.minDistance = 2
controls.maxDistance = 10

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    //Update objects
    donutGroup.rotation.set(Math.PI * elapsedTime * 0.02 , Math.PI * elapsedTime * 0.01, 1)
    coneGroup.rotation.set(Math.PI * elapsedTime * 0.01 , Math.PI * elapsedTime * 0.02, 1)

    // Update camera
    camera.position.x += Math.cos(Math.PI * 2 * elapsedTime * 0.15) * 0.005
    camera.position.y -= Math.sin(Math.PI * 2 * elapsedTime * 0.2) * 0.01
    camera.position.z -= Math.sin(Math.PI * 2 * elapsedTime * 0.01) * 0.001

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()